
.footer {
    margin-left: 30%;
    /*margin-left: 45%;*/
    /* background-color: #f8f8f8;*/
    position: fixed;
    bottom: 0;
    font-size: 83%;
    
    /* height:100vh;*/
}

/*tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .footer {
        margin-left:10%;
    }
 }


@media screen and (max-width:600px) {
    .footer {
        margin-left: 10%;
    }
}