
#joystick {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: black;
    position: relative;
    touch-action: none;
    /*border:3px solid black;*/
    opacity:50%;
}
#handle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    touch-action: none;
   /* top: 60px;
    left: 60px;*/
}
.joystick-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.debug-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: gray;
}


pre {
    margin: 20px;
    background-color: #1E1E1E;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1px;
    overflow-x: auto; /* Set overflow-x to auto */
    white-space: pre-wrap; /* Wrap long lines of text */
    max-width: 100%; /* Set maximum width to fit the page */
}

code {
    font-family: monospace;
    font-size: 1.1rem;
}

.info-text {
    font-size: 1.1rem;
    margin: 20px;
    text-align: justify;
    line-height: 1.5;
}

p {
    margin: 20px;
    text-align: justify;
    line-height: 1.5;
}

/* Responsive styles */

@media screen and (max-width: 768px) {
    .joystick-container {
        margin-top: 20px;
    }

    .debug-container {
        margin-top: 10px;
    }

    pre {
        margin: 10px;
    }

    .info-text,
    p {
        margin: 10px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .joystick-container {
        margin-top: 10px;
    }

    .debug-container {
        margin-top: 5px;
    }

    pre {
        margin: 5px;
    }

    .info-text,
    p {
        margin: 5px;
        font-size: 0.8rem;
    }
}
