.Map {
    /*height: 100vh;
    width: 100vw;*/
    height:81vh;
    width:79vw;
    margin-left:0%;
}

.map-container {
    height: 100%;
    width: 100%;
}
.map-title{
   /* margin-left: -2%;*/
   color:#555;
}
.map-h2 {
    margin: -2%;
}

