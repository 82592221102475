.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.home,
.services,
.reports,
.contact,
.events,
.support {
    display: flex;
    margin-left: 260px;
    font-size: 3rem;
}
.user {
    color: #004953;
    left: 92%;
    font-size: 120%;
    text-decoration: none;
}
.marginleftright {
    margin-left: 12%;
    width: 95%;
}
.rowbox {
    border: solid;
    border-width: 1px;
    border-color: #95c92c;
    margin-top: 3%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
