
body {
    /*  background: linear-gradient(to left, green, white);*/
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  /*  color: white;*/
    font-family: Garamond;
}
.container-ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 83.5vh;
    width: 100%;
}
.form-box {
    width: 30%;
    height: auto;
    /* background-color: yellowgreen;*/
    /* background-image: linear-gradient(-45deg, yellowgreen 0%, #FFFB7D 100%);*/
    /* background: linear-gradient(to left, #FFFB7D, #85FFBD);*/
    /* background: linear-gradient(-45deg, #85FFBD 0%, #FFFB7D 100%);*/
    /* background: linear-gradient(-45deg, #85FFBD 0%, gray 100%);*/
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* opacity: 0.7;*/
    border-radius: 2%;
    border-color: #95c92c;
    border-style: groove;
    margin-top: -10%;
    border-width: 1.8px;
}
.header-form {
    margin-bottom: 15px;
}
button {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #FFFB7D;
    
}
.message {
    display: flex;
    justify-content: space-between;
    justify-content: right;
    color: darkslategrey;
}
.message a {
        color: black;
}
.fa fa-lock {
    font-size: 68px;
}
.teal-color {
    color: yellowgreen;
}
.black-color {
    color: black;
}
.btn-block {
    /* background-image: linear-gradient(-45deg,yellowgreen 0%, #FFFB7D 100%);*/
    /*color: black;*/
    background-color: yellowgreen;
    font-weight:bold;
}
.errorMessage {
    color: red;
}
.fa fa-eye {
    font-size:22px; color: #99cc33;
}
/*Desktop*/
@media screen and (min-width: 1025px) {
    .user {
        margin-left: auto;
    }

    .logo {
        width: 4.5%;
        object-fit: cover;
    }
}
/*tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .logo {
        width: 9%;
        object-fit: cover;
    }
    .user {
        margin-left: auto;
    }

    .form-box {
        width: 50%;
    }
}
/*Mobile*/
@media screen and (max-width:767px) {
    .logo {
        width: 15.5vw;
        object-fit: cover;
    }

    .user {
        margin-left: auto;
    }

    .form-box {
        width: 90%;
    }
}

@media screen and (max-width:479px) {
    .logo {
        width: 15.5vw;
        object-fit: cover;
    }

    .user {
        margin-left: auto;
    }

    .form-box {
        width: 100%;
    }
}
