.robot-components {
  display: grid;
}
.row.robot-row {
  position: relative;
  width: 100%;
}
.row.robot-row.status-info {
  margin: 10px;
}
.joystickContainer {
  position: relative;
}
.row-item {
    text-align: center;
   margin-left:5%;
}
.row-item.label-name {
  display: flex;
  margin-top:3%;
  /*margin-left:5%;*/
}
.row-item.joystick-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*margin-top: 20px;*/
  margin-top: 5%;
 }
.row-item.speed-container {
  display: flex;
  /*margin-top: 20px;*/
  margin-top:5%;
}
.row-item.icon-container {
  /* padding: 5px;*/
  padding: 1%;
}
.iconContainer {
  margin: 0;
  /*max-height: 60px;
  max-width: 60px;*/
  left:3%;
}

.btn.btn-container {
  display: flex;
 /* margin: 20%;*/
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    font-family: "Poppins";
    font-weight: 900;
}
.btn-container {
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
}
.videoContainer {
  position: relative;
  margin: 20px;
  margin-top: 2%;
  align-items: center;
}

img {
  background-position: center;
  height:auto;
  /*width: 60px;*/
}

img.imgButton {
  background-position: center;
/*  height:60px;*/
  height:auto;
  width: 100px;
  margin-left:-70%;
 }

img.imgSpeed {
  background-position:center;
  max-height:auto;
  width:110%;
}
.user{

}
.divmain {
    width: 100%;
    /*background-color: #85FFBD;
    background-image: linear-gradient(-45deg, #85FFBD 0%, #FFFB7D 100%);
    background-attachment: fixed;*/
}
.btn1 {
    /*background-color: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;*/
    /*background-color: rgba(0, 0, 0, 0.0);
    border: none;
    border-radius:8px*/
}
.dvscroll {
    overflow: auto;
}
.tdcursorpointer {
    cursor: pointer;
    color: #95c92c;
}
/*input[type="checkbox"]:checked::after {
    color: #95c92c;
}*/
input[type=checkbox]:checked {
    background-color: #95c92c !important;
    color: #ffffff !important;
}
.dvcamera {
    width: 115%;
}
.iframe
{
    height:500px;
    width:123%;
}
.camcol{
    width:100%;
}
.camcolclose {
    width:5%;
}
.hidecamera {
    float: right;
    width: 3%;
    background-color: rgba(0, 0, 0, 0.14);
    /* float:left;*/
    /*background-color: #ffffff;*/
    border-style: none;
    z-index:2050;
    position: absolute;
    right:0%;
    top:0%;
}
.operation-icon {
    color: #99cc33;
    width: 100%;
    text-decoration:none;
 }
.operation-icon1 {
    color: yellow;
    width: 100%;
    font-size:85%;
    /*font-family:Garamond;*/
    cursor:pointer;
    text-decoration:none;
}

.icon-size{
    font-size:80%;
    cursor:pointer;
}
.modal-icon-size {
    font-size: 85%;
}

.header-icon-size {
    font-size:140%;
}

.col_right_align{
    float:right;
}
.modal_connect_stop_link {
    color:#99cc33;
    width: 100%;
    font-size:100%;
    /*font-family:Garamond;*/
    cursor: pointer;
    text-decoration: none;
  }
.gc-sp-text {
    font-size: 75%;
   /* z-index:1200;*/
    color:black;
}
#connect-icon {
    font-Size: 50px;
}
.modal-80w
{
    /*width:120%;*/
    max-width:none!important;
}
#forward-icon {
    font-Size: 50px;
}
#backward-icon {
    font-Size: 50px;
}
#right-icon {
    font-Size: 50px;
}
#turn-icon {
    font-Size: 50px;
}
#left-icon {
    font-Size: 50px;
}
#stop-icon {
    font-Size: 50px;
}
#off-icon {
    font-Size: 50px;
}
.iframe {
    height: 250px;
    width: 100%;
}
.iframe1 {
    height: 500px;
    width: 100%;
  }

.operation-icon {
    cursor:Pointer; 
    font-size:35px; 
    color: #99cc33;
}
.operation-icon-1 {
    cursor: Pointer;
    color: #99cc33;
    float: right;
}
.operation-icon-text {
    cursor: Pointer;
    font-size: 22px;
    color: #99cc33;
    margin-top:3%;
}

#inneriframe {
    /* position: absolute;*/
    /*  top: -10px;
    left: -120px;*/
    width: 100%;
    height: 500px;
}
.inneriframe1 {
    /* position: absolute;*/
    /*  top: -10px;
    left: -120px;*/
    width: 100%;
    height: 500px;
}
.icon-div {
  width:35%;
  margin-left:35%;
  position:absolute;
  top:50%;
 /* right:0%;*/
  z-index:1000;
}
.icon-buttons {
    width:100%;
 }
.icon-col {
    width:auto;
 }

.icon-left {
    position: absolute;
    top: 46%;
    left: 0;
}
.icon-right {
    position: absolute;
    top: 46%;
    right: 0;
}

.icon-turn_stop {
    position: absolute;
    bottom: 0%;
    left:18%;
}
.icon-stop {
    position: absolute;
    bottom: 0%;
    right: 18%;
}
.icon-forward {
    position: absolute;
    left: 40%;
    bottom:0%;
}
.icon-forward-2 {
    position: absolute;
    left: 40%;
    bottom: 10%;
}
.icon-backward-2 {
    position: absolute;
    left: 55%;
    bottom: 10%;
}

.icon-forward-right {
    position: absolute;
    right:3%;
    top:0%;
}
.icon-forward-left {
    position: absolute;
    left: 0%;
    top:0%;
}
.icon-backward {
    position: absolute;
    left: 55%;
    bottom: 0;
}
.icon-backward-2 {
    position: absolute;
    left: 55%;
    bottom:10%;
}
.icon-backward-right {
    position: absolute;
    right: 0%;
    bottom:0%;
}
.icon-backward-right-2 {
    position: absolute;
    right: 0%;
    bottom:5%;
}
.icon-backward-left {
    position: absolute;
    left: 0%;
    bottom:0%;
}
.icon-backward-left-2 {
    position: absolute;
    left: 0%;
    bottom: 5%;
}
.icon-forward_1 {
    position: absolute;
    top: 40%;
    Left: 25%;
    bottom: 0%;
}

.icon-backward_1 {
    position: absolute;
    Left: 45%;
    bottom:8%;
 }
.icon-right_1 {
    position: absolute;
    top: 58%;
   /* left: 60%;*/
    right:22%;
   /* padding-left:5%;*/
}

.icon-left_1 {
    position: absolute;
    top: 58%;
    left:22%;
}

.icon-turn_stop_1 {
    position: absolute;
    top: 58%;
    left: 45%;
}
.icon-turn_stop-2 {
    position: absolute;
    bottom: 5%;
    left: 45%;
    cursor:pointer;
    z-index:1200;
}
.icon-forward-2 {
    position: absolute;
    left: 30%;
    bottom: 5%;
}

.icon-backward-2 {
    position: absolute;
    left: 60%;
    bottom:5%;
}
.icon-stop-1 {
    position: absolute;
    bottom:0%;
    right:4%;
 }
.icon-connect_1 {
    position: absolute;
    bottom: 0%;
    left: 0%;
   /* font-size: 85%;*/
    /*background-color:#fff;
    opacity:0.5;
    color:#A0A0A0;*/
}
.icon-control_panel_1 {
    position: absolute;
    bottom:0%;
    left: 39%;
    z-index:1200;
  }
.control_panel{
    top:50%;
    background-color:#fff;
    /*opacity:0.8;*/
    z-index:1200;
}
.connection_status {
    color: orange; position:absolute; 
    z-Index:1;
    top:0;
    left:0%;
    font-size:0%;
}

.camera_connection_status {
    color: orange;
    position: absolute;
    z-Index: 1;
    top: 0;
    right: 1%;
    font-size: 0%;
}
#connect-icon_1 {
    font-Size:40px;
}
.display_robot_name {
    position: absolute;
    top: 30%;
    Left: 41%;
    bottom: 0%;
    color:yellow ;
    /*background-color: #fff;
    height:40px;
    padding:1%;
    opacity:0.5;*/
    z-index:1200;
}
.dvcb {
    position: absolute;
    top: -19%;
    Left: 15%;
    bottom: 0%;
    color: yellow;
    z-index: 1;
}
.controllerbattery {
    width: 45px;
 }
.dvmb {
    position: absolute;
    top: -19%;
    left:60%;
    bottom: 0%;
    color: yellow;
    z-index: 1;
}
.motorbattery {
    width:45px;
}
.modalcontainer {
    display: none; padding:10px;
    position: absolute; width:47%; top:25%; left:25%; z-index:1800;
}
    
.col_gc_sp {
    display:inline;
}
.chart {
   width:40%;
}
.container_multi_robots {
    position: relative;
    overflow: hidden;
    height:320px;
    width:100%;
    padding-top: 75%; /* 16:9= 56.25, 4:3=75%; Aspect Ratio */
}
.responsive-multi_robots_iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* width:100%;
    height: 100%;*/
    border: none;
    z-index:1000;
    /*background-color: maroon;*/
}
.container_1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%; /* 16:9= 56.25, 4:3=75%; Aspect Ratio */
}
.container_2 {
    position: relative;
    width: 100%;
    /* height:auto;*/
    overflow: hidden;
    padding-top: 66.66%; /* 3:2 Aspect Ratio */
}
.responsive-iframe_1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* width:100%;
    height: 100%;*/
    border: none;
    float: left;
    /*background-color: maroon;*/
}
#outerdiv {
    width:80%;
  /*  height: 100%;*/
    /*overflow: hidden;*/
    position: relative;
    padding-top:56.25%;
}
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px
}

.grid > div {
    font-size: 20px;
    padding: 1rem;
   /* color: yellow;*/
    text-align: center;
    background:#f8f8f8;
}
.btndelete
{
    cursor:pointer;
    font-size:100%;
    text-decoration:none;
}
.btncancel {
    cursor: pointer;
    font-size: 100%;
    text-decoration: none;
}

/* Extra small devices (phones, 600px and down) */
/*@media only screen and (max-width: 600px) {

    .responsive-iframe_1 {
        width: 300px;
        height: 300px;
        overflow: hidden;
    }
}*/
/* Small devices (portrait tablets and large phones, 600px and up) */
/*@media only screen and (min-width: 600px) {
    responsive-iframe_1 {
        width: 320px;
        height: 320px;
        overflow: hidden;
    }
}
*/
/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {
    responsive-iframe_1 {
        width: 400px;
        height: 400px;
        overflow: hidden;
    }
}*/

/* Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (min-width: 992px) {
    responsive-iframe_1 {
        width: 400px;
        height: 400px;
        overflow: hidden;
    }
}
*/
/* Extra large devices (large laptops and desktops, 1200px and up) */
 /*   @media only screen and (min-width: 1200px) {
        responsive-iframe_1 {
            width: 400px;
            height: 400px;
            overflow:hidden;
        }
    }*/
 /*Mobile*/
 @media screen and (min-width: 768px) and (max-width: 1024px) {
    .iframe {
        height: 500px;
        width: 300px;
    }
    .iframe1 {
        height: 400px;
        width: 100%;
    }
    .hidecamera {
        float: right;
        width: 3%;
        background-color: rgba(0, 0, 0, 0.14);
        border-style: none;
        z-index: 2050;
        position: absolute;
        right: 0%;
        top: 0%;
    }
    .container_1 {
        width: 320px;
        height: 320px;
        position: relative;
        overflow: hidden;
       /* width: 100%;*/
       /* padding-top: 56.25%;*/
        /*background-color: orange;*/
    }
    .responsive-iframe_1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
       /* width: 280px;
        height:280px;*/
        border: none;
        /*background-color: orange;*/
    }  
    #outerdiv {
        width: 330px;
        height: 330px;
        overflow: hidden;
        position: relative;
    }
    img.imgSpeed {
        width: 90%;
    }
    .inneriframe {
            /* position: absolute;*/
            /*  top: -10px;
    left: -120px;*/
        width: 100%;
        height: 500px;
   }

        #connect-icon {
            font-Size: 30px;
        }

        #forward-icon {
            font-Size: 30px;
        }

        #backward-icon {
            font-Size: 30px;
        }

        #right-icon {
            font-Size: 30px;
        }

        #turn-icon {
            font-Size: 40px;
        }

        #left-icon {
            font-Size: 40px;
        }

        #stop-icon {
            font-Size: 40px;
        }

        #off-icon {
            font-Size: 40px;
        }
        .chart{
            width:20%;
        }

    .icon-left_1 {
        position: absolute;
        top: 58%;
        left: 10%;
    }

    .icon-right_1 {
        position: absolute;
        top: 58%;
        right: 0%;
    }
    .modalcontainer {
        display: none;
        padding: 10px;
        position: absolute;
        width: 90%;
        top: 25%;
        left: 5%;
        z-index: 1800;
    }
    }
 /*table landscape*/
@media screen and (min-width:800px) and (max-width: 1280px) and (orientation:landscape) {
    .hidecamera {
        float: right;
        width: 3%;
        background-color: rgba(0, 0, 0, 0.14);
        /* float:left;*/
        /*background-color: #ffffff;*/
        border-style: none;
        z-index: 2050;
        position: absolute;
        right: 0%;
        top: 0%;
    }
    .container_1 {
        height:400px;
        width:400px;
        position: relative;
        overflow: hidden;
        /* width: 100%;*/
        padding-top:56.25%;
       /* background-color: yellow;*/
    }
    .responsive-iframe_1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
       /* height: 300px;
        width: 300px;*/
        border: none;
        /*background-color: yellow;*/

    }

    #outerdiv {
        width: 410px;
        height: 410px;
        overflow: hidden;
        position: relative;
    }

   /* .operation-icon {
        font-size: 30px;
    }*/


}
/*tablet portrait*/
@media screen and (min-width:601px) and (max-width:768px) {
    .hidecamera {
        float: right;
        width: 3%;
        background-color: rgba(0, 0, 0, 0.14);
        /* float:left;*/
        /*background-color: #ffffff;*/
        border-style: none;
        z-index: 2050;
        position: absolute;
        right:1%;
        top: 0%;
    }
    container_1 {
        height:310px;
        width:310px;
        position: relative;
        overflow: hidden;
       /* width: 100%;*/
        padding-top: 56.25%;
    }
    .responsive-iframe_1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
       /* height:250px;
        width:250px;*/
        border: none;
        background-color: green;
    }
    #outerdiv {
        width:320px;
        height:320px;
        overflow: hidden;
        position: relative;
    }
    /*.operation-icon
    {
        font-size:30px;
    }*/
    .icon-left_1 {
        position: absolute;
        top: 58%;
        left:10%;
    }
    .icon-right_1 {
        position: absolute;
        top: 58%;
        right:0%;
    }
    .modalcontainer {
        display: none;
        padding: 10px;
        position: absolute;
        width:90%;
        top: 25%;
        left: 5%;
        z-index: 1800;
    }
 }
/*Mobile*/
@media screen and (max-width:600px) {
    .column {
        width: 100%;
    }
   img.imgSpeed {
        width: 50%;
   }

  .chart {
        width: 100%;
  }
  .iframe1 {
            height: 620px;
            width: 100%;
   }
    .hidecamera {
        float: right;
        width: 3%;
        background-color: rgba(0, 0, 0, 0.14);
        /* float:left;*/
        /*background-color: #ffffff;*/
        border-style: none;
        z-index: 2050;
        position: absolute;
        right: 1%;
        top: 0%;
    }
    .container_1 {
        height: 320px;
        width: 320px;
        position: relative;
        overflow: hidden;
        background-color: blue;
        /*width: 100%;*/
        /*padding-top: 56.25%;*/
        /*padding-top100%;*/ /* 1:1 Aspect Ratio */
    }
    .responsive-iframe_1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
       /* height: 200px;
        width: 290px;*/
        border: none;
        background-color: green;
    }
    #outerdiv {
        width: 225px;
        height: 225px;
        overflow: hidden;
        position: relative;
        padding-top:100%;
    }
    .operation-icon {
        cursor: Pointer;
        font-size:25px;
    }
    .icon-forward {
        position: absolute;
        left:37%;
        bottom: 0%;
    }

    .icon-backward {
        position: absolute;
        left: 52%;
        bottom: 0;
    }
   /* .operation-icon {
        font-size: 30px;
    }*/

    .operation-icon {
        font-size:25px;
    }
    /**/
   .icon-left_1 {
        position: absolute;
        top: 58%;
        left:10%;
    }
    .icon-right_1 {
        position: absolute;
        top: 58%;
        right:5%;
    }
    .modalcontainer {
        display: none;
        padding: 10px;
        position: absolute;
        width: 90%;
        top: 25%;
        left: 5%;
        z-index: 1800;
    }
   /* .icon-backward_1 {
        position: absolute;
        bottom:15%;
        left:45%;
    }*/

    }
    /**/ /*input[type=radio] {
    accent-color: #95c92c;
    color: #ffffff;
}*/
    /*@media screen and (min-width: 768px) {
    html {
        font-size: 16px;
    }
    img.imgButton {
        background-position: center;
        height: auto;
        width: 30%;
        margin-left: -60%;
    }
}*/
    /* On small screens, set height to 'auto' for sidenav and grid */
    /*@media screen and (max-width: 767px) {
    img.imgButton {
        background-position: center;
        height: auto;
        width: 60px;
        margin-left:-20%;
    }
}*/
