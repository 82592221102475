@font-face {
    font-family: Garamond;
   /* src: url('../../../../public/Garamond.ttf');*/
}

.btn.panel-button {
    width: 100px;
    height: 60px;
    border-radius: 20px;
    font-size: 0.9rem;
    display: inline-block;
    text-align: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    font-family: 'Garamond';
    font-weight: bolder;
    font-size: 17px;
    background-color: #a0d92e; /*#99cc33*/ /*#95c92c*/
}
.btn.panel-button:disabled,
.btn.panel-button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.btn.panel-button.active {
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }


/*.btn.panel-button {
    padding: 10px;
    margin: 10px;
}*/

.row.panel-row {
    display: flex;
    justify-content: space-around;
    margin-top:20px;
    text-align: center;
    width:100%;
}
.tblwidth{
    width:100%;
}

