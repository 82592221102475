.Light {
    border-radius: 50%;
    height: 25px;
    margin: 10px;
    width: 25px;
}

.PowerLight {
    align-items: center;
    background: #f9f9f9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /*padding: 5px;*/
    width:37%;
    /*z-index: 10;*/
    position: absolute;
    top: 20%;
    left: 5%;
    opacity: 0.7;
}
@keyframes blinkRed {

    0%, 49% {
        background-color: red;
    }

    50%, 100% {
        background-color: black;
    }
}

@keyframes blinkYellow {

    0%, 49% {
        background-color: yellow;
    }

    50%, 100% {
        background-color: black;
    }
}

@keyframes blinkGreen {

    0%, 49% {
        background-color: green;
    }

    50%, 100% {
        background-color: black;
    }
}

.red {
    background-color: red;
    animation: blinkRed 9s infinite;
}

.yellow {
    background-color: yellow;
    animation: blinkYellow 9s infinite;
    animation-delay: 3s;
}

.green {
    background-color: green;
    animation: blinkGreen 9s infinite;
    animation-delay: 6s;
}

