#h3{
    margin-top:-2%;
}
/* Style tab links for robot operation and map */
.tablink {
    background-color: #f2f2f2;
    color: #555;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 19px;
    width: 25%;
}
.tablink:hover {
    background-color:#99cc33;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
    color: white;
    display: none;
    padding: 100px 20px;
    height: 100%;
 }

#Operation {
    background-color: #fff;
}
#OperationStatus {
    background-color: #fff;
}
#Map {
    background-color: #fff;
}
#Status {
    background-color: #fff;
}
#Chart {
    background-color: #fff;
    color:#000;
}
#AddPlant {
    background-color: #fff;
}

#SearchPlant {
    background-color: #fff;
}
#EditPlant {
    background-color: #fff;
}
/*tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .tabcontent {
        width:100%;
    }
}
/**/
@media screen and (max-width:600px) {
    .column {
        width: 100%;
    }
}