/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
    border: black;
}

body {
    background-color: #f8f8f8;
  /*  background-color: #85FFBD;
    background-image: linear-gradient(-45deg, #85FFBD 0%, #FFFB7D 100%);*/
    background-attachment: fixed;
}


code {
    color: #E01A76;
}

.btn-dark {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.row {
}

.navbar {
    background: none;
}
