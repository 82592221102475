.container-ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 83.5vh;
    width: 100%;
}

.form-container {
    max-width: 100%;
    width:40%;
    margin-left: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.form-container1 {
    max-width: 100%;
 }
.page-container {
    max-width: 100%;
}
.search-container {
    max-width: 100%;
    margin-left: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.alink
{
    cursor:pointer;
}



.form-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}
.form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
}
.form-message {
    color: red;
    font-size:100%;
    margin-top: -10px;
    margin-bottom: 10px;
}
.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #99cc34 /*#007bff*/;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.submit-button:hover {
        background-color: #0056b3;
}
