body {
}
.user {
   display: block; 
   margin-left:64%;
   padding:30px;
   color: #004953;
}
.logo_1 {
    top:-1.2%;
    left:-2.5%;
    width:12%;
    position:absolute;
    z-index:100;
   /* object-fit: cover;*/
}
.online {
    color: #99cc33;
}
.offline {
    color: red;
}



/*Desktop*/
@media screen and (min-width: 1025px) {
    .user {
        margin-left: auto;
        font-size:90%;
    }
    .logo {
        width:100%;
        object-fit: cover;
    }
    .logo_2 {
        width:6%;
        object-fit: cover;
    }
   .log_1 {
        width:14%;
    }
}
/*tablet*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .logo {
        width: 9%;
        object-fit: cover;
    }
    .logo_2 {
        width: 9%;
        object-fit: cover;
    }
    .logo_1 {
        width:19.5%;
    }

    .user {
        margin-left: auto;
        font-size: 90%;
    }

    .form-box {
        width: 100%;
    }
    .column {
        width: 100%;
    }
   
}
/*Mobile*/
@media screen and (max-width:767px) {
    .logo {
        width: 15.5vw;
        object-fit: cover;
    }
    .logo_2 {
        width: 15.5vw;
        object-fit: cover;
    }
    .logo_1 {
        width: 30.5vw;
        object-fit: cover;
    }
    .user {
        margin-left: auto;
        font-size: 90%;
    }
    .form-box {
        width: 100%;
    }
    .h2{
        font-size:60%;
    }
    .column {
        width: 100%;
    }
}
@media screen and (max-width:479px) {
    .logo {
        width: 15.5vw;
        object-fit: cover;
    }
    .logo_2 {
        width: 15.5vw;
        object-fit: cover;
    }
    .logo_1 {
        width: 20.5vw;
        object-fit: cover;
    }
    .user {
        margin-left: auto;
        font-size: 90%;
    }

    .form-box {
        width: 100%;
    }

    .column {
        width: 100%;
    }
}